import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import type { ContentServiceV1 } from '@oneaudi/content-service';

export interface Content {
  readonly headline: string;
  readonly link_text: string;
  readonly link_url: string;
  readonly priceDisplayOption_showBasePrice: boolean;
  readonly showVat: boolean;
  readonly discoverLinkIsPrimaryCta: boolean;
  readonly priceFootnotes: string[];
  readonly caeDisplayOption_showCae: boolean;
  readonly vtpDisplayOption_showVTP: boolean;
  readonly carlines: string[];
  readonly disclaimer?: string;
}

interface FalconContent {
  readonly __type: 'aem-headless';
  readonly fields: Content;
}

export const ContentContext = createContext<Content>(null as unknown as Content);

export interface ContentContextProviderProps {
  readonly contentService: ContentServiceV1;
  readonly children?: ReactNode;
}

const normalizeContent = (contentService: ContentServiceV1): Content | undefined => {
  const cmsContent = contentService.getContent() as Content | FalconContent | undefined;

  if (cmsContent === undefined) {
    return cmsContent;
  }

  if ('__type' in cmsContent && cmsContent.__type === 'aem-headless') {
    return cmsContent.fields;
  }

  return cmsContent as Content;
};

export const ContentContextProvider: FC<ContentContextProviderProps> = ({
  contentService,
  children,
}) => {
  const [content, setContent] = useState<Content | undefined>(() =>
    normalizeContent(contentService),
  );

  useEffect(() => {
    const contentChangeHandler = () => {
      setContent(normalizeContent(contentService));
    };

    contentService.onContentChange(contentChangeHandler);

    return () => {
      contentService.removeOnContentChange(contentChangeHandler);
    };
  }, [contentService]);

  if (!content) {
    return null;
  }

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};
