import styled from 'styled-components';
import { Button, Text } from '@audi/audi-ui-react';

export const StyledActionWrapper = styled.div`
  width: 100%;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.m});
  position: relative;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: grid;

    grid-template-areas:
      'flyout'
      'button-group';
    grid-template-rows: 0 auto;
    min-width: 305px;
    width: fit-content;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    min-width: 350px;
  }
`;

export const StyledButtonWrapper = styled.div<{ hasConfigureLink: boolean; hasVTPButton: boolean }>`
  grid-area: button-group;
  pointer-events: auto;
  display: grid;
  gap: var(${(props) => props.theme.responsive.spacing.xxs});
  @media (min-width: ${(props) => props.theme.breakpoints.s + 106}px) {
    grid-template-columns: ${(props) => (props.hasConfigureLink ? '1fr 1fr' : '1fr')};
    grid-template-rows: ${(props) => (props.hasConfigureLink ? 'auto auto' : 'auto auto auto')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto;

    align-items: center;
  }
`;

// override to adapt to dark theme
export const StyledButton = styled(Button)`
  &.primaryBtn {
    color: var(${(props) => props.theme.colors.base.brand.black});
    background-color: var(${(props) => props.theme.colors.base.brand.white});
    box-shadow: inset 0 0 0 1px var(${(props) => props.theme.colors.base.brand.white});
    &:hover {
      color: var(${(props) => props.theme.colors.base.brand.black});
      background-color: var(${(props) => props.theme.colors.base.grey[30]});
    }
  }

  &.secondaryBtn {
    color: var(${(props) => props.theme.colors.base.brand.white}) !important;
    box-shadow: inset 0 0 0 1px var(${(props) => props.theme.colors.base.brand.white}) !important;
    &:hover {
      color: var(${(props) => props.theme.colors.base.grey[30]}) !important;
      box-shadow: inset 0 0 0 1px var(${(props) => props.theme.colors.base.grey[30]}) !important;
    }
  }
`;

export const VTPButton = styled(Button)<{ flyoutOpen: boolean }>`
  ${({ flyoutOpen }) => (flyoutOpen ? 'box-shadow: none !important;' : '')};
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column: span 2;
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0 0;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-left: 34px;
    padding: 0;
  }
  & > div > svg {
    flex: 0 0 auto;
  }
  // override to adapt to dark theme
  & > div > svg,
  & > div > span {
    color: var(${(props) => props.theme.colors.base.brand.white});
  }
  &:hover,
  &:not(:disabled):hover {
    & > div > svg,
    & > div > span {
      color: var(${(props) => props.theme.colors.base.grey[30]}) !important;
    }
  }
`;

export const VTPWrapper = styled.div`
  justify-self: center;
  @media (min-width: ${(props) => props.theme.breakpoints.s + 106}px) {
    grid-column: 1 / 3;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: 0;
    justify-self: left;

    grid-column: auto;
  }
`;

export const Flyout = styled.div<{ actionsFlyoutIsVisible: boolean }>`
  grid-area: flyout;
  position: absolute;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 60px 16px;
  bottom: ${(props) => (props.actionsFlyoutIsVisible ? '-16px' : '-220px')};
  opacity: ${(props) => (props.actionsFlyoutIsVisible ? 1 : 0)};
  transition:
    bottom 0.25s ease-in-out,
    opacity 0.25s ease-in-out;

  background-color: var(${(props) => props.theme.colors.base.brand.white});
  pointer-events: ${(props) => (props.actionsFlyoutIsVisible ? 'auto' : 'none')};
  z-index: 20;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    bottom: ${(props) => (props.actionsFlyoutIsVisible ? '-48px' : '-220px')};
    min-width: 332px;
    max-width: 332px;
    min-height: 220px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    min-width: 440px;
    max-width: 440px;
  }
`;

export const FlyoutCloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  color: var(${(props) => props.theme.colors.ui.primary});
`;

export const StyledText = styled(Text)`
  color: var(${(props) => props.theme.colors.interaction.primary.disabled}) !important;
  border-bottom: 1px solid var(${(props) => props.theme.colors.interaction.primary.disabled}) !important;
  font-weight: 700;
`;
