import { Text } from '@audi/audi-ui-react';
import { FootnoteReferenceV2 } from '@oneaudi/feature-app-utils';
import { useQuery } from '@oneaudi/onegraph-client';
import React, { useContext } from 'react';
import styled from 'styled-components';
import type {
  CarlineQuery,
  CarlineQueryVariables,
} from '../../graphql/documents/carline.generated';
import { CarlineDocument } from '../../graphql/documents/carline.generated';
import { ContentContext } from '../context/ContentContext';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { UniversalStateContext } from '../context/UniversalStateContext';
import { ActionWrapper } from './ActionWrapper';
import { CurrentCarlineContext } from '../context/CurrentVehicleContext';

const CarlineInfo = styled.div`
  position: relative;
  padding: var(${({ theme }) => theme.responsive.spacing.m})
    var(${({ theme }) => theme.responsive.spacing.pageMargin}) 0;

  grid-area: 2 / 1 / 2 / 1;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-area: 1 / 1 / 1 / span 2;
    display: flex;
    flex-direction: column;
    align-self: end;
  }

  > p,
  > p > span {
    color: ${({ theme }) => (theme.name === 'Audi Light Theme' ? 'white' : 'black')} !important;
  }
`;

const CarlineAction = styled.div`
  position: relative;
  padding: 0 var(${({ theme }) => theme.responsive.spacing.pageMargin})
    var(${({ theme }) => theme.responsive.spacing.xxl});

  grid-area: 3 / 1 / 3 / 1;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-area: 2 / 1 / 2 / 1;
  }
`;

const PriceText = styled(Text)`
  pointer-events: auto;
  color: var(${(props) => props.theme.colors.base.brand.white}) !important;

  & a:active,
  & a:focus,
  & a:active span,
  & a:focus span {
    color: var(${(props) => props.theme.colors.base.brand.white});
  }

  & a:hover,
  & a:hover span {
    color: var(${(props) => props.theme.colors.base.grey[30]});
  }
`;

export const CarlineBlock = () => {
  const { featureServices } = useFeatureAppEnv();
  const numberFormatter = featureServices['audi-number-formatter-service'];
  const { countryCode, language } = featureServices['gfa:locale-service'];
  const content = useContext(ContentContext);
  const { id } = useContext(CurrentCarlineContext);
  const universalState = useContext(UniversalStateContext);

  const { data } = useQuery<CarlineQuery, CarlineQueryVariables>(CarlineDocument, {
    variables: {
      identifier: {
        brand: 'A',
        id,
        country: countryCode,
        language,
      },
    },
  });

  if (!data || !universalState) {
    return null;
  }

  return (
    <>
      <CarlineInfo>
        <Text variant="order3">{data.carline.name}</Text>
        {content.priceDisplayOption_showBasePrice && data.carline.prices.minPrice?.value && (
          <PriceText variant="copy2" spaceStackStart="xxs">
            {universalState.i18nTexts.minPriceText.replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${value}',
              numberFormatter.formatCurrency(data.carline.prices.minPrice.value),
            )}
            {content.priceFootnotes?.map((footnote: string, i: number) => (
              <React.Fragment key={footnote}>
                {i > 0 && <sup>{', '}</sup>}
                <FootnoteReferenceV2 footnoteId={footnote} data-testid={`price-footnote_${i}`} />
              </React.Fragment>
            ))}{' '}
            {content.showVat && (
              <Text variant="copy3" as="span">
                {universalState.i18nTexts.vat}
              </Text>
            )}
          </PriceText>
        )}
      </CarlineInfo>
      <CarlineAction>
        <ActionWrapper carlineQuery={data} key={id} />
      </CarlineAction>
    </>
  );
};
